<template>
  <v-container>
    <v-card flat>
      <v-card-title class="headline">
        Displays
        <v-spacer />
        <v-btn
          class="mr-2"
          color="grey"
          outlined
        >
          <v-icon left>
            mdi-tag
          </v-icon>
          Tags
        </v-btn>
        <v-btn
          color="success"
          @click="pairingDialog = true"
        >
          <v-icon left>
            mdi-cast-connected
          </v-icon>
          Pair
        </v-btn>
      </v-card-title>
      <v-card-text>
        In order to add displays
        <router-link :to="{ name: 'downloads' }">
          download
        </router-link>
        and install the Mammut OS windows application on the display you wish to use.
      </v-card-text>

      <!-- plans -->
      <v-card-text>
        <expand-card title="Plans">
          <v-alert
            v-if="organization.remainingTrialDays >= 0"
            dense
            border="left"
            type="warning"
            color="secondary"
            dismissable
          >
            You have {{ organization.remainingTrialDays }} days remaining in your organization trial period. During this time you can use one display with all the available functionalities.
          </v-alert>
          <v-card-subtitle>
            Used
          </v-card-subtitle>
          <v-row no-gutters align="center">
            <v-chip
              v-for="(quantity, plan) in organization.usedPlans"
              :key="plan"
              class="ma-2"
              color="white"
              light
              label
            >
              <v-avatar left color="grey" class="white--text" tile>
                {{ quantity || 0 }}
              </v-avatar>
              {{ plan | capitalizeFirstLetter }}
            </v-chip>
          </v-row>
          <v-card-subtitle>
            Available
          </v-card-subtitle>
          <v-row no-gutters align="center" justify-sm="end">
            <v-chip v-if="organization.remainingTrialDays >= 0" class="ma-2" color="white" light label>
              <v-avatar left :color="organization.isUsingTrialDisplay ? 'grey' : 'success'" class="white--text" tile>
                {{ organization.isUsingTrialDisplay ? '0' : '1' }}
              </v-avatar>
              Trial Plan
            </v-chip>
            <v-chip
              v-for="plan in ['advertising', 'view', 'touch']"
              :key="plan"
              class="ma-2"
              color="white"
              light
              label
            >
              <v-avatar left color="grey" class="white--text" tile>
                {{ (subscription[plan] || 0) - organization.usedPlans[plan] }}
              </v-avatar>
              {{ plan | capitalizeFirstLetter }}
            </v-chip>
            <v-spacer />
            <!-- <v-btn
              class="ma-2"
              color="grey"
              outlined
              :to="{ name: 'subscription' }"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add plans
            </v-btn> -->
          </v-row>
        </expand-card>
      </v-card-text>

      <!-- filter and sort
      <v-card-text>
        <expand-card title="Filters" :value="true">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Search"
                  dense
                  hide-details
                  clearable
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="filters.screens"
                  :items="$store.state.screens"
                  label="Filter By Screens"
                  color="primary lighten-2"
                  prepend-inner-icon="mdi-monitor"
                  item-value="id"
                  outlined
                  chips
                  dense
                  hide-details
                  multiple
                  clearable
                >
                  <template v-slot:selection="{ attrs, selected, select, item }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      small
                      @click="select"
                      @click:close="removeDeviceFilter(item)"
                    >
                      {{ item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <template v-if="typeof item !== 'object'">
                      <v-list-item-content v-text="item" />
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" />
                        <v-list-item-subtitle>
                          {{ item.resolution | formatResolution }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="filters.tags"
                  :items="availableTags"
                  label="Filter By Tags"
                  color="primary lighten-2"
                  prepend-inner-icon="mdi-monitor"
                  item-value="id"
                  outlined
                  chips
                  dense
                  hide-details
                  multiple
                  clearable
                >
                  <template v-slot:selection="{ attrs, selected, select, item }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      :color="item.color"
                      close
                      small
                      dark
                      @click="select"
                      @click:close="removeTagFilter(item)"
                    >
                      {{ item.title }}
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <template v-if="typeof item !== 'object'">
                      <v-list-item-content v-text="item" />
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title" />
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </expand-card>
      </v-card-text>
      -->
      <!-- displays -->
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="processedItems"
        :search="search"
        item-key="name"
        class="m-data-table"
        no-data-text="No displays to show"
      >
        <template #[`item.name`]="{ item }">
          <v-list-item
            :to="{ name: 'display', params: { id: item.id } }"
            dense
            selectable
            class="ma-0 pa-0"
            style="max-width: 250px;"
          >
            <v-list-item-icon class="mr-1">
              <v-avatar :color="item.lastOnlineAt === true ? 'success' : 'error'" size="24" class="mr-2">
                <v-icon small dark>
                  mdi-wifi{{ item.lastOnlineAt !== true ? '-off' : '' }}
                </v-icon>
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.plan | displayPlan }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template #[`item.appVersion`]="{ item }">
          <span v-if="item.appVersion">
            v. {{ item.appVersion }}
          </span>
        </template>
        <template #[`item.status`]="{ item }">
          <span v-if="item.lastOnlineAt === true">
            Online Now
          </span>
          <span v-else-if="item.lastOnlineAt" class="caption">
            {{ item.lastOnlineAt | formatDate }}
          </span>
          <span v-else class="caption grey--text">
            No connection yet
          </span>
        </template>
        <template #[`item.layout`]="{ item }">
          <v-list-item
            v-if="item.layout"
            :to="{ name: 'layout', params: { id: item.layoutId } }"
            dense
            selectable
            class="ma-0 pa-0"
            style="max-width: 250px;"
          >
            <v-list-item-icon class="mr-1">
              <v-icon>
                mdi-collage
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.layout.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.layout.minPlan | displayPlan }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <span v-else>
            No Layout Configured
          </span>
        </template>
        <template #[`item.planStatus`]="{ item }">
          <v-chip
            v-if="!item.planStatus"
            color="error"
            dense
          >
            Disabled
          </v-chip>
          <v-chip
            v-else-if="item.planStatus === 'free'"
            color="primary"
            dense
          >
            Watermarked
          </v-chip>
          <v-chip
            v-else
            color="success"
            dense
          >
            Active
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="pairingDialog"
      max-width="300px"
    >
      <validation-observer
        ref="pairingForm"
        v-slot="{ valid }"
        tag="form"
        @submit.prevent="pair"
      >
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              Pair new device
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              icon
              @click="pairingNumber = ''; pairingDialog = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text v-if="error">
            <v-alert
              text
              type="error"
            >
              <v-row no-gutters align="center" justify="space-between">
                {{ error }}
                <v-btn
                  icon
                  color="black"
                  @click="error = ''"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-row>
            </v-alert>
          </v-card-text>
          <v-card-text>
            To pair your screen please enter the 4 digit number shown on the screen.
          </v-card-text>
          <v-card-text>
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:4|max:4"
              name="Pairing Number"
            >
              <v-text-field
                v-model="pairingNumber"
                outlined
                autofocus
                counter="4"
                maxlength="4"
                label="Pairing Number"
                type="number"
                class="display-1 pairing-number--input"
                :error-messages="errors"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              outlined
              color="primary"
              type="submit"
              :loading="pairing"
              :disabled="!valid || pairing"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import getRandomMachineName from '@/utils/get_random_machine_name'
export default {
  name: 'Displays',
  data: () => ({
    search: '',
    filters: {
      screens: [],
      tags: []
    },
    pairingDialog: false,
    pairingNumber: '',
    pairingPlan: '',
    pairing: false,
    error: '',
    useLayout: false,
    selected: [],
    headers: [
      {
        text: 'Display Name',
        align: 'left',
        value: 'name'
      },
      {
        text: 'OS',
        align: 'right',
        value: 'appVersion',
        sortable: false
      },
      {
        text: 'Last Online',
        align: 'left',
        value: 'status',
        sortable: false
      },
      {
        text: 'Current Layout',
        align: 'left',
        value: 'layout',
        sortable: false
      },
      {
        text: 'Plan Status',
        value: 'planStatus'
      },
      {
        value: 'data-table-expand'
      }
    ],
    availableTags: [
      {
        id: 'floor_1',
        title: 'Floor 1',
        color: 'red'
      },
      {
        id: 'floor_2',
        title: 'Floor 2',
        color: 'blue'
      },
      {
        id: 'floor_3',
        title: 'Floor 3',
        color: 'green'
      },
      {
        id: 'haus_1',
        title: 'Building 1',
        color: 'cyan'
      },
      {
        id: 'haus_4',
        title: 'Building 4',
        color: 'orange'
      }
    ]
  }),
  computed: {
    ...mapGetters(['organization', 'displays', 'layouts', 'subscription', 'requiredPlans']),
    processedItems() {
      return this.displays.filter((x) => {
        if (this.filters.screens.length && !this.filters.screens.includes(x.screenId)) {
          return false
        }
        if (this.filters.tags.length && (!x.tags || !this.filters.tags.every(tag => x.tags.includes(tag)))) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    async pair() {
      this.error = ''
      if (!this.pairingNumber) {
        return
      }
      const form = this.$refs.pairingForm
      const formIsValid = await form.validate()
      if (formIsValid) {
        this.pairing = true
        const doc = await this.$db.collection('pairing-numbers').doc(this.pairingNumber).get()
        if (doc.exists) {
          const { machineId, resolution } = doc.data()
          const user = await this.$getAsyncCurrentUser()
          await this.$db.collection('displays').doc(machineId).set({
            name: `${getRandomMachineName()} Display`,
            createdAt: this.$nowstamp(),
            updatedAt: this.$nowstamp(),
            userId: user.uid,
            organizationId: this.organization.id,
            resolution,
            plan: 'free'
          })
          const { docs } = await this.$db.collection('pairing-numbers').where('machineId', '==', machineId).get()
          await Promise.all(docs.map((x) => {
            return this.$db.collection('pairing-numbers').doc(x.id).delete()
          }))
          this.pairingNumber = ''
          this.pairingDialog = false
          this.$router.push({ name: 'display', params: { id: machineId } })
        } else {
          this.error = 'Invalid Number'
        }
        this.pairing = false
      }
    },
    removeDeviceFilter(item) {
      const index = this.filters.screens.indexOf(item.id)
      if (index >= 0) {
        this.filters.screens.splice(index, 1)
      }
    },
    removeTagFilter(item) {
      const index = this.filters.tags.indexOf(item.id)
      if (index >= 0) {
        this.filters.tags.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="sass">
  .pairing-number--input
    input[type='number']
      text-align: center
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
      -webkit-appearance: none
</style>
